import { useMemo } from 'react';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import ResendInvitation from './ResendInvitation';
import UpdateStatus from './UpdateStatus';
import WithdrawInvitation from './WithdrawInvitation';
import { useGetUser } from '../../../contexts';
import { Options as OptionsIcon } from '../../../assets';
import { AdminUser, PersonnelUser, UserRole, UserStatus } from '../../../types';

interface Props {
  user: AdminUser | PersonnelUser;
}

export default function OptionsMenu({ user }: Props): JSX.Element {
  const { role, status, uid } = user;
  const currentUser = useGetUser();
  const isInvitationPending = useMemo(
    () => status === UserStatus.PENDING,
    [status],
  );
  const showUpdateStatus = useMemo(() => {
    if (status === UserStatus.PENDING) {
      return;
    }

    if (role === UserRole.OWNER && currentUser.role !== UserRole.OWNER) {
      return false;
    }

    return true;
  }, [currentUser, role, status]);

  return (
    <Menu>
      <MenuButton as={Button} display="inline" variant="ghost" size="sm">
        <OptionsIcon />
      </MenuButton>

      <MenuList>
        {isInvitationPending && <ResendInvitation uid={uid} />}
        {isInvitationPending && <WithdrawInvitation uid={uid} />}
        {showUpdateStatus && <UpdateStatus user={user} />}
      </MenuList>
    </Menu>
  );
}
